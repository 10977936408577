var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-form-group',{attrs:{"label-for":"is_national","description":"Si tu cuenta es mexicana marca esta casilla"}},[_c('b-form-checkbox',{attrs:{"id":"is_nacional"},model:{value:(_vm.is_national),callback:function ($$v) {_vm.is_national=$$v},expression:"is_national"}},[_vm._v("Cuenta nacional")])],1),_c('b-form-group',{attrs:{"label":"Nombre del beneficiario","label-for":"beneficiary_name"}},[_c('validation-provider',{attrs:{"rules":"required"}},[_c('b-form-input',{attrs:{"id":"beneficiary_name"},model:{value:(_vm.beneficiary_name),callback:function ($$v) {_vm.beneficiary_name=$$v},expression:"beneficiary_name"}})],1)],1),_c('b-form-group',{attrs:{"label":"Apellido del beneficiario","label-for":"beneficiary_last_name"}},[_c('validation-provider',{attrs:{"rules":"required"}},[_c('b-form-input',{attrs:{"id":"beneficiary_last_name"},model:{value:(_vm.beneficiary_last_name),callback:function ($$v) {_vm.beneficiary_last_name=$$v},expression:"beneficiary_last_name"}})],1)],1),_c('b-form-group',{attrs:{"label":"Banco","label-for":"bank"}},[_c('validation-provider',{attrs:{"rules":"required"}},[_c('b-form-input',{attrs:{"id":"bank"},model:{value:(_vm.bank),callback:function ($$v) {_vm.bank=$$v},expression:"bank"}})],1)],1),_c('b-form-group',{attrs:{"label":_vm.getLabelClabe(),"label-for":"clabe"}},[_c('validation-provider',{attrs:{"rules":"required"}},[_c('b-form-input',{attrs:{"id":"clabe"},model:{value:(_vm.clabe),callback:function ($$v) {_vm.clabe=$$v},expression:"clabe"}})],1)],1),_c('b-form-group',{attrs:{"label":"Cuenta","label-for":"account"}},[_c('validation-provider',{attrs:{"rules":"required"}},[_c('b-form-input',{attrs:{"id":"account"},model:{value:(_vm.account),callback:function ($$v) {_vm.account=$$v},expression:"account"}})],1)],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email"},model:{value:(_vm.email_value),callback:function ($$v) {_vm.email_value=$$v},expression:"email_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Teléfono"}},[_c('validation-provider',{attrs:{"name":"Celular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-phone-number-input',{attrs:{"preferred-countries":['MX', 'AR', 'BR', 'CO', 'CR', 'CL', 'EC', 'PE', 'UY', 'US'],"show-code-on-list":true,"translations":{
          countrySelectorLabel: 'Código de país',
          countrySelectorError: 'Seleccione país',
          phoneNumberLabel: 'Celular con WhatsApp o Telegram'},"default-country-code":"MX","required":true,"no-example":true,"no-validator-state":true,"error":_vm.errorPhone},on:{"update":_vm.countryPhone},model:{value:(_vm.telephone),callback:function ($$v) {_vm.telephone=$$v},expression:"telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.is_national)?_c('b-form-group',{attrs:{"label":"Ciudad","label-for":"city"}},[_c('validation-provider',{attrs:{"rules":"required"}},[_c('b-form-input',{attrs:{"id":"city"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1)],1):_vm._e(),(!_vm.is_national)?_c('b-form-group',{attrs:{"label":"Dirección","label-for":"address"}},[_c('validation-provider',{attrs:{"rules":"required"}},[_c('b-form-input',{attrs:{"id":"address"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1):_vm._e(),_c('div',{staticClass:"mt-1 text-center"},[_c('b-button',{attrs:{"variant":"outline-success","disabled":invalid || _vm.errorPhone},on:{"click":function($event){return _vm.updatePyamentData()}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.save'))+" ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }