import axios from 'axios'
import config from '@/services/config'

export default {

  updateCampaignContent(campaign_uuid, content_uuid, data) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/manage/influencer-update/${content_uuid}`,
      data)
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },
  getMetrics(campaign_uuid, data) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/content/stats`,
      data)
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },
  addContent(campaign_uuid, data) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/manage/add/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  addContentNoSession(campaign_uuid, data) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/manage/influencer-add/`,data)
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  getInvitation(slug, network, username) {
    return axios.get(`${config.urlCore}/api/v1/campaign/external/${slug}/${network}/${username}/`, {
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401)
          return error.response
      })
  },
  accpetTerms(slug, network, username) {
    return axios.get(`${config.urlCore}/api/v1/campaign/external/accept-terms/${slug}/${network}/${username}/`, {
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401)
          return error.response
      })
  },
  updatePaymentData(data) {
    return axios.post(`${config.urlCore}/api/v1/campaign/external/update/payment-data/`,
      data
    ).then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },
  changeInfluencerStatus(campaign_influencer_uuid, status) {
    return axios.post(`${config.urlCore}/api/v1/campaign/external/update/status/${campaign_influencer_uuid}`,
      {status}
    ).then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },
  validateSession(slug, network, username) {
    return axios.get(`${config.urlCore}/api/v1/campaign/external/validate-session/${slug}/${network}/${username}/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401)
          return error.response
      })
  },
  register(data) {
    return axios.post(`${config.urlCore}/api/v1/campaign/external/register/`,
      data
    ).then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },
}