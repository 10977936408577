<template>
  <div class="m-2">
    <validation-observer ref="loginForm" #default="{invalid}">
      <b-form class="auth-login-form mt-2" @submit.prevent="makeLogin">
        <!-- email -->
        <b-form-group>
          <label for="login-email">{{ $t('login.email') }}</label>
          <validation-provider
            #default="{ errors }"
            :name="$t('login.email')"
            :vid="$t('login.email')"
            rules="required|email"
          >
            <b-form-input
              id="login-email"
              v-model="email"
              :state="errors.length > 0 ? false:null"
              name="login-email"
              :placeholder="$t('login.email')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- forgot password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="login-password">{{ $t('login.password') }}</label>
            <b-link target="_blank" :to="{name:'auth-forgot-password'}">
              <small>{{ $t('login.forgotPassword') }}</small>
            </b-link>
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('login.password')"
            :vid="$t('register.password')"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="login-password"
                v-model="password"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
                :placeholder="$t('register.password')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          type="submit"
          class="blue-button"
          variant="blue-button"
          block
          :disabled="invalid"
        >
          {{ $t('login.button') }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BButton, BLink, BInputGroup, BInputGroupAppend } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import utils from '@/libs/utils'
import abilities from '@/libs/acl/abilities'

export default {
  name: 'loginInvitation',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  props: {
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    setUser(data) {
      utils.setPermissions(data.user);
      const abilitie = abilities.getAbilitie(data.user)
      useJwt.setToken(data.access_token)
      useJwt.setRefreshToken(data.refresh_token)
      utils.setuserData(data, abilitie)
      this.$ability.update(abilitie)
      this.$store.commit('appConfig/UPDATE_IS_VERIFIED', data.user.is_verified)
      window.location.reload()
    },
    makeLogin() {
      this.$vs.loading()
      useJwt.login({
        email: this.email,
        password: this.password,
        is_app: true
      })
        .then(response => response.data.response)
        .then(response => {
          this.setUser(response)
          this.$vs.loading.close()
        })
    }
  }
};
</script>

<style>
</style>
