<template>
  <validation-observer #default="{ invalid }">
    <b-form>
      <b-form-group
        label-for="is_national"
        description="Si tu cuenta es mexicana marca esta casilla"
      >
        <b-form-checkbox id="is_nacional" v-model="is_national">Cuenta nacional</b-form-checkbox>
      </b-form-group>

      <b-form-group
        label="Nombre del beneficiario"
        label-for="beneficiary_name"
      >
        <validation-provider rules="required">
          <b-form-input id="beneficiary_name" v-model="beneficiary_name"/>
        </validation-provider>
      </b-form-group>

      <b-form-group
        label="Apellido del beneficiario"
        label-for="beneficiary_last_name"
      >
        <validation-provider rules="required">
          <b-form-input id="beneficiary_last_name" v-model="beneficiary_last_name"/>
        </validation-provider>
      </b-form-group>

      <b-form-group
        label="Banco"
        label-for="bank"
      >
        <validation-provider rules="required">
          <b-form-input id="bank" v-model="bank"/>
        </validation-provider>
      </b-form-group>

      <b-form-group
        :label="getLabelClabe()"
        label-for="clabe"
      >
        <validation-provider rules="required">
          <b-form-input id="clabe" v-model="clabe"/>
        </validation-provider>
      </b-form-group>

      <b-form-group
        label="Cuenta"
        label-for="account"
      >
        <validation-provider rules="required">
          <b-form-input id="account" v-model="account"/>
        </validation-provider>
      </b-form-group>

      <b-form-group
        label="Email"
        label-for="email"
      >
        <validation-provider rules="required|email" name="email" #default="{ errors }">
          <b-form-input id="email" v-model="email_value"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group label="Teléfono">
      <validation-provider
        #default="{ errors }"
        name="Celular"
        rules="required"
      >
        <vue-phone-number-input
          @update="countryPhone"
          v-model="telephone"
          :preferred-countries="['MX', 'AR', 'BR', 'CO', 'CR', 'CL', 'EC', 'PE', 'UY', 'US']"
          :show-code-on-list="true"
          :translations="{
            countrySelectorLabel: 'Código de país',
            countrySelectorError: 'Seleccione país',
            phoneNumberLabel: 'Celular con WhatsApp o Telegram'}"
          default-country-code="MX"
          :required="true"
          :no-example="true"
          :no-validator-state="true"
          :error="errorPhone"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

      <b-form-group
        v-if="!is_national"
        label="Ciudad"
        label-for="city"
      >
        <validation-provider rules="required">
          <b-form-input id="city" v-model="city"/>
        </validation-provider>
      </b-form-group>

      <b-form-group
        v-if="!is_national"
        label="Dirección"
        label-for="address"
      >
        <validation-provider rules="required">
          <b-form-input id="address" v-model="address"/>
        </validation-provider>
      </b-form-group>

      <div class="mt-1 text-center">
        <b-button
          variant="outline-success"
          :disabled="invalid || errorPhone"
          @click="updatePyamentData()"
        >
          {{$t('dashboard.save')}}
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm, BFormGroup, BFormCheckbox, BFormInput, BButton } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import invitation_service from "@/services/invitation";
import { required, email } from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import utils from '@/libs/utils'
import abilities from '@/libs/acl/abilities'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'paymentData',
  components: {
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BButton,
    ValidationObserver,
    ValidationProvider,
    VuePhoneNumberInput
  },
  props: {
    campaign: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      required,
      email,
      is_national: false,
      beneficiary_name: '',
      beneficiary_last_name: '',
      bank: '',
      clabe: '',
      account: '',
      email_value: '',
      telephone: '',
      errorPhone: false,
      country: '',
      city: '',
      address: ''
    }
  },
  created() {
  },
  methods: {
    getLabelClabe() {
      return this.is_national ? 'CLABE' : 'ABBA / SWIFT / BIC'
    },
    formatNumber(number) {
      const string = number.replace(/ /g,'')
      return `${string.substring(0, 3)} ${string.substring(3, 6)} ${string.substring(6, string.length)}`;
    },
    countryPhone (data) {
      this.country = data.countryCode
      if (data.phoneNumber) {
        this.telephone = this.formated_phone = this.formatNumber(data.phoneNumber)
      } else {
        this.telephone = this.formated_phone = ''
      }
      this.validPhone()
    },
    validPhone () {
      this.errorPhone = this.formated_phone.length > 12 || this.formated_phone.length < 11
    },
    setUser(data) {
      utils.setPermissions(data.user);
      const abilitie = abilities.getAbilitie(data.user)
      useJwt.setToken(data.access_token)
      useJwt.setRefreshToken(data.refresh_token)
      utils.setuserData(data, abilitie)
      this.$ability.update(abilitie)
      this.$store.commit('appConfig/UPDATE_IS_VERIFIED', data.user.is_verified)
      window.location.reload()
    },
    updatePyamentData() {
      this.$vs.loading()
      const form_data = {
        is_national: this.is_national,
        beneficiary_name: this.beneficiary_name,
        beneficiary_last_name: this.beneficiary_last_name,
        bank: this.bank,
        clabe: this.clabe,
        account: this.account,
        email: this.email_value,
        telephone: this.telephone,
        country: this.country,
        city: !this.is_national ? this.city : null,
        address: !this.is_national ? this.address : null,
        username: this.$route.params.username,
        network: this.$route.params.network,
      }

      invitation_service.updatePaymentData(form_data)
        .then(response => {
          this.setUser(response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('dashboard.saved'),
              variant: 'success',
              icon: null,
              text: ''
            }
          })
          this.$emit('savePaymentData')
          utils.updatePaymentData(response.user.payment_data)
          this.$vs.loading.close()
        })
    }
  },
  watch: {
  }
};
</script>

<style>
.country-selector__list {
  min-width: 300px !important;
  height: 400px !important;
  min-height: 400px;
}
.country-selector__list__item {
  display: block;
  padding: 2em 1em;
}
.dots-text {
  order: 1;
  width: 180px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
