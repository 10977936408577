<template>
  <div class="d-flex h-100 flex-column">
    <div class="d-flex justify-content-center flex-wrap mt-1">
      <div class="col-12 col-sm-4 col-lg-12 col-xl-4">
        <b-media class="d-block col-12">
          <template #aside>
            <div class="container-avatar-table">
              <b-avatar
                size="120px"
                :src="utils.getAssetUrl(influencer.file_for_profile)"
                variant="light-primary"
              />
              <div class="container-avatar-table-icon sidebar-icon">
                <span
                  :class="`${utils.getClassNetworkSpan(influencer.network)} mr-1 style-border-icon`"
                  :style="`border:1px solid #${utils.getColor(influencer.network)}`">
                </span>
              </div>
            </div>
          </template>
        </b-media>
      </div>
      <div class="col-12 col-sm-5 col-lg-12 col-xl-5 d-flex flex-wrap text-no-break">
        <h3 class="col-12 text-center mt-1">
          {{influencer.username}}
        </h3>
        <b-badge :variant="utils.statusVariant(campaign_influencer.status)" class="mt-1">
          {{ $t(utils.getStatus(campaign_influencer.status)) }}
        </b-badge>
        <div class="col-12 text-center mt-1 row">
          <span class="text-muted col-12">
            {{$t('lists.followers')}}
          </span>
          <span class="col-12">
            {{utils.getFormat(influencer.followers)}}
          </span>
        </div>
        <div class="col-12 text-center mt-1 row">
          <span class="text-muted col-12">
            {{ $t('budgetcard') }}
          </span>
          <span class="col-12">
            ${{utils.separatebycomma(campaign.budget)}}
          </span>
        </div>
      </div>
    </div>
    <div class="m-2" v-html="campaign.custom_message"></div>
    <div class="text-center m-2" v-if="this.campaign.campaign_influencer.status_influencer !== 'approved'">
      <p>{{$t('campaigns.questionApproveProposal')}}</p>
      <b-button variant="success" @click="verifyTYC()">{{$t('campaigns.realty.accept')}}</b-button>
    </div>
    <div class="m-2" v-if="this.campaign.campaign_influencer.status_influencer === 'approved' && !influencer.has_payment_data">
      <paymentData :campaign="campaign" @savePaymentData="savePaymentData"/>
    </div>
    <div class="m-2 text-center" v-if="this.campaign.campaign_influencer.status_influencer === 'approved' && influencer.has_payment_data">
      <strong>{{$t('campaigns.proposalAccepted')}}</strong>
      <p>{{$t('campaigns.paymentDataregistered')}} <a target="_blank" href="mailto:contacto@brandme.la">contacto@brandme.la</a></p>
    </div>
    <div class="m-2">

      <b-alert :variant="publishedContents() === campaign.campaign_influencer.content.length ? 'success' : 'warning'" show class="p-1 d-flex" v-if="publishedContents() > 0">
        <div class="mr-1"><feather-icon icon="CheckCircleIcon" size="20"/></div>
        <div>
         {{$t('invitation.you_have')}} {{publishedContents(campaign.campaign_influencer.content)}} {{$t('invitation.content_published')}}
        </div>
      </b-alert>

      <b-alert variant="warning" show class="p-1 d-flex" v-if="publishedContents() === 0">
        <div class="mr-1"><feather-icon icon="AlertTriangleIcon" size="20"/></div>
        <div>
          {{$t('invitation.no_content_published')}}
        </div>
      </b-alert>

      <b-alert variant="warning" show class="p-1 d-flex" v-if="!campaign.campaign_influencer.accept_tyc">
        <div class="mr-1"><feather-icon icon="AlertTriangleIcon" size="20"/></div>
        <div>
          {{$t('invitation.dont_forget_tyc')}}
        </div>
      </b-alert>

      <b-alert variant="success" show class="p-1 d-flex" v-if="campaign.campaign_influencer.accept_tyc">
        <div class="mr-1"><feather-icon icon="CheckCircleIcon" size="20"/></div>
        <div>
          {{$t('invitation.accepted_tyc')}}
        </div>
      </b-alert>

      <b-alert variant="warning" show class="p-1 d-flex" v-if="this.campaign.campaign_influencer.status_influencer !== 'approved'">
        <div class="mr-1"><feather-icon icon="AlertTriangleIcon" size="20"/></div>
        <div>
          {{$t('invitation.accept_campaign')}}
        </div>
      </b-alert>

      <b-alert variant="success" show class="p-1 d-flex" v-if="campaign.campaign_influencer.influencer.has_payment_data">
        <div class="mr-1"><feather-icon icon="CheckCircleIcon" size="20"/></div>
        <div>
          {{$t('invitation.data_bank')}}
        </div>
      </b-alert>

      <b-alert variant="danger" show class="p-1 d-flex" v-if="this.campaign.campaign_influencer.status_influencer === 'rejected'">
        <div class="mr-1"><feather-icon icon="XSquareIcon" size="20"/></div>
        <div>
          {{$t('invitation.reject_campaign')}}
        </div>
      </b-alert>
    </div>

    <div class="mt-auto border-top-lg pt-1 pl-1 pb-1" v-if="this.campaign.campaign_influencer.status_influencer === 'pending'">
      <b-link class="decline-campaign" @click="changeStatus('rejected')">{{$t('campaigns.rejectBtn')}}</b-link>
    </div>
  </div>
</template>

<script>
import { 
  BButton, 
  BLink,
  BMedia,
  BAvatar,
  BBadge,
  BAlert,
} from "bootstrap-vue";
import paymentData from "./paymentData.vue";
import invitation_service from "@/services/invitation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import utils from '@/libs/utils';

export default {
  name: 'acceptDecline',
  components: {
    BButton,
    BLink,
    paymentData,
    BMedia,
    BAvatar,
    BBadge,
    BAlert,
  },
  props: {
    campaign: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      utils,
      influencer: this.campaign.campaign_influencer.influencer,
      campaign_influencer: this.campaign.campaign_influencer
    }
  },
  created() {
    const user = utils.getUserData()
    if (user) {
      this.influencer.has_payment_data = user.has_payment_data
    } else {
      this.influencer.has_payment_data = false
    }
  },
  methods: {
    publishedContents() {
      let counter = 0;
      this.campaign.campaign_influencer.content.map((content) => {
        if (content.image_content !== null || content.url_info !== null) counter += 1;
      })
      return counter;
    },
    verifyTYC() {
      if (this.campaign.campaign_influencer.accept_tyc) {
        this.changeStatus('approved');
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: 'Error',
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t('invitation.first_accept_tyc')
          }
        });
      }
    },
    savePaymentData() {
      this.influencer.has_payment_data = true
    },
    changeStatus(status) {
      invitation_service.changeInfluencerStatus(this.campaign.campaign_influencer.uuid, status)
        .then(response => {
          const title = status === 'approved' ? this.$t('campaigns.proposalAccepted') : this.$t('campaigns.proposalRejected')
          const variant = status === 'approved' ? 'success' : 'danger'
          this.$toast({
            component: ToastificationContent,
            props: {
              title,
              icon: "BellIcon",
              variant,
            }
          },
          {
            timeout: 20000
          });
          this.campaign.campaign_influencer.status_influencer = response.campaign_influencer.status_influencer
        })
    }
  },
  watch: {
  }
};
</script>

<style>
  .text-no-break{
    justify-content: center !important;
    word-wrap: normal !important
  }
  .end-div {
    margin-top: auto !important;
  }
  .decline-campaign, .decline-campaign:hover {
    color: #B04254;
  }
  .decline-campaign:hover {
    text-decoration: underline;
  }

  .border-top-lg {
    border-top: 1px solid #EFEFEF;
  }
  .container-avatar-table {
    position: relative;
  }
  .media-aside {
    justify-content: center !important;
  }
  .container-avatar-table-icon {
    position: absolute; 
    bottom: 0px; 
    right: 0em;
  }
  .container-avatar-table .b-avatar-img {
    padding: 0.5em !important;
    background-color: #f6f6f6;
    margin: 0 auto !important;
  }
  .style-border-icon {
    font-size: 1.4em; 
    border-radius: 50%; 
    background: white; 
    padding: 2px;
  }
</style>
