<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      :next-button-text="$t('next')"
      :finish-button-text="$t('registerInvitation')"
      :back-button-text="$t('return')"
      @on-complete="formSubmitted"
    >

      <!-- accoint details tab -->
      <tab-content :title="$t('login.accountDetails')" :before-change="validationForm">
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col md="6">
              <b-form-group :label="$t('register.name')" label-for="name">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('register.name')"
                  :vid="$t('register.name')"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="first_name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('register.name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group :label="$t('register.lastname')" label-for="lastname">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('register.lastname')"
                  :vid="$t('register.lastname')"
                  rules="required"
                >
                  <b-form-input
                    id="lastname"
                    v-model="last_name"
                    name="lastname"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('register.lastname')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Email" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="email_value"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('register.email')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Password" label-for="password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-form-input
                    id="password"
                    v-model="password_value"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- Información complementarios -->
      <tab-content :title="$t('login.personalInformation')" :before-change="validationFormInfo">
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col md="6">
              <b-form-group label="Teléfono">
                <validation-provider
                  #default="{ errors }"
                  name="Celular"
                  rules="required"
                >
                  <vue-phone-number-input
                    @update="countryPhone"
                    v-model="telephone"
                    :translations="{
                      countrySelectorLabel: 'Código de país',
                      countrySelectorError: 'Seleccione país',
                      phoneNumberLabel: 'Celular con WhatsApp o Telegram'}"
                    default-country-code="MX"
                    :required="true"
                    :no-example="true"
                    :no-validator-state="true"
                    :error="errorPhone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Cuéntanos sobre tu contenido" label-for="description_content">
                <b-form-textarea
                  id="description_content"
                  v-model="description_content"
                  placeholder="Escribir mensaje..."
                  rows="3"
                  :required="true"
                  max-rows="4"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import invitation_service from "@/services/invitation";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import utils from '@/libs/utils'
import abilities from '@/libs/acl/abilities'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'registerInvitation',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    FormWizard,
    TabContent,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput
  },
  props: {
  },
  data() {
    return {
      required,
      email,
      email_value: '',
      password_value: '',
      first_name: '',
      last_name: '',
      telephone: '',
      errorPhone: false,
      country: '',
      description_content: ''
    }
  },
  created() {
  },
  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    formatNumber(number) {
      const string = number.replace(/ /g,'')
      return `${string.substring(0, 3)} ${string.substring(3, 6)} ${string.substring(6, string.length)}`;
    },
    countryPhone (data) {
      this.country = data.countryCode
      if (data.phoneNumber) {
        this.telephone = this.formated_phone = this.formatNumber(data.phoneNumber)
      } else {
        this.telephone = this.formated_phone = ''
      }
      this.validPhone()
    },
    validPhone () {
      this.errorPhone = this.formated_phone.length > 12 || this.formated_phone.length < 11
    },
    setUser(data) {
      utils.setPermissions(data.user);
      const abilitie = abilities.getAbilitie(data.user)
      useJwt.setToken(data.access_token)
      useJwt.setRefreshToken(data.refresh_token)
      utils.setuserData(data, abilitie)
      this.$ability.update(abilitie)
      this.$store.commit('appConfig/UPDATE_IS_VERIFIED', data.user.is_verified)
      window.location.reload()
    },
    formSubmitted() {
      this.$vs.loading()
      const form_data = new FormData()
      form_data.append('username', this.$route.params.username)
      form_data.append('network', this.$route.params.network)
      form_data.append('slug_campaign', this.$route.params.slug)

      form_data.append('first_name', this.first_name)
      form_data.append('last_name', this.last_name)
      form_data.append('email', this.email_value)
      form_data.append('password', this.password_value)
      form_data.append('is_outofhome', false)

      form_data.append('telephone', this.telephone)
      form_data.append('country', this.country)
      form_data.append('question_text', this.description_content)

      invitation_service.register(form_data)
        .then(response => {
          this.$vs.loading.close()
          if (response.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                variant: 'danger',
                text: 'No se pudo registrar'
              }
            })
          } else {
            this.setUser(response)
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              variant: 'danger',
              text: 'No se pudo registrar'
            }
          })
        })
    }
  },
  watch: {
  }
};
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
</style>
