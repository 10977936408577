<template>
  <b-row>
    <!-- Impresiones -->
    <b-col class="col-12">
      <validation-provider rules="required">
        <b-form-group
          :label="$t('campaigns.impressions_label')"
          :description="''"                  
        >
          <b-input-group class="m-auto">
            <b-input-group-prepend is-text>
              <feather-icon icon="UsersIcon" />
            </b-input-group-prepend>
            <input-number-comma :price="mutable_content.number_of_impressions" identifier="number_of_impressions" @changeValue="changeValue" :placeholder="$t('campaigns.impressions_placeholder')"></input-number-comma>
          </b-input-group>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Likes -->
    <b-col class="col-12" v-if="show_likes">
      <validation-provider rules="required">
        <b-form-group
          :label="$t('campaigns.likes_label')"
          :description="''"                     
        >
          <b-input-group class="m-auto">
            <b-input-group-prepend is-text>
              <b-icon icon="heart-fill"/>
            </b-input-group-prepend>
            <input-number-comma :price="mutable_content.number_of_likes" identifier="number_of_likes" @changeValue="changeValue" :placeholder="$t('campaigns.likes_placeholder')"></input-number-comma>
          </b-input-group>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Comentarios -->
    <b-col class="col-12" v-if="show_comments">
      <validation-provider rules="required">
        <b-form-group 
          :label="$t('campaigns.comments_label')"
          :description="''"                     
        >
          <b-input-group class="m-auto">
            <b-input-group-prepend is-text>
              <b-icon icon="chat"/>
            </b-input-group-prepend>
            <input-number-comma :price="mutable_content.number_of_comments" identifier="number_of_comments" @changeValue="changeValue" :placeholder="$t('campaigns.comments_placeholder')"></input-number-comma>
          </b-input-group>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Compartidas -->
    <b-col class="col-12">
      <validation-provider rules="required">
        <b-form-group
          :label="$t('campaigns.shares_label')"
          :description="''"                  
        >
          <b-input-group class="m-auto">
            <b-input-group-prepend is-text>
              <b-icon icon="box-arrow-up-right"/>
            </b-input-group-prepend>
            <input-number-comma :price="mutable_content.number_of_shares" identifier="number_of_shares" @changeValue="changeValue" :placeholder="$t('campaigns.shares_placeholder')"></input-number-comma>
          </b-input-group>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Saved -->
    <b-col class="col-12" v-if="show_saved">
      <validation-provider rules="required">
        <b-form-group 
          :label="$t('campaigns.saved_label')"
          :description="''"                     
        >
          <b-input-group class="m-auto">
            <b-input-group-prepend is-text>
              <b-icon icon="bookmark"/>
            </b-input-group-prepend>
            <input-number-comma :price="mutable_content.number_of_times_saved" identifier="number_of_times_saved" @changeValue="changeValue" :placeholder="$t('campaigns.saved_placeholder')"></input-number-comma>
          </b-input-group>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Engagements readonly -->
    <b-col class="col-12">
      <b-form-group
        :label="$t('engagements')"
        :description="''"
      >
        <b-input-group class="m-auto">
          <b-input-group-prepend is-text>
            <b-icon icon="emoji-heart-eyes"/>
          </b-input-group-prepend>
          <b-form-input v-model="engagements" readonly :placeholder="$t('engagements')"></b-form-input>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-row class="d-flex justify-content-center my-2">
        <b-button
          variant="success success-button-invitations"
          class="success-button-invitations"
          :disabled="external_form || invalid_internal"
          @click="onSubmit()"
        >
          {{$t('dashboard.save')}}
        </b-button>
      </b-row>
    </b-col>
  </b-row>
  
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";

import {
  BInputGroup,
  BInputGroupPrepend,
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BIcon,
  BFormInput
} from "bootstrap-vue";
import inputNumberComma from "@/views/components/inputNumberComma.vue";
import utils from "@/libs/utils";

export default {
  name: 'formEngagements',
  components: {
    ValidationProvider,
    BInputGroup,
    BInputGroupPrepend,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    inputNumberComma,
    BIcon,
    BFormInput
  },
  data() {
    return {
      required,
      engagements: 0,
      mutable_content: {},
      show_likes: true,
      show_comments: true,
      invalid_internal: true,
      show_saved: true
    };
  },
  props: {
    external_form: {
      type: Boolean,
      default: true
    },
    content: {
      required: true,
      type: Object,
    },
    net: {
      required: true,
      type: String,
    },
    new_layout: {
      type: Boolean
    },
  },
  created() {
    this.mutable_content = this.content
    
    if (this.mutable_content.content_type === 'podcast') {
      this.show_likes = false
      this.show_comments = false
    }

    if (this.mutable_content.network === 'blog') {
      this.show_likes = false
      if (this.mutable_content.content_type === 'post') this.show_saved = false
    }

    if (this.mutable_content.content_type === 'streaming' || this.mutable_content.content_type === 'clip' || this.mutable_content.content_type === 'story') this.show_saved = false

    const total = this.mutable_content.number_of_likes + this.mutable_content.number_of_comments + this.mutable_content.number_of_shares + this.mutable_content.number_of_times_saved
    this.engagements = utils.separatebycomma(total)
    this.checkFormInternalExternal()
  },
  methods: {
    checkFormInternalExternal() {
      if (this.mutable_content.content_type === 'podcast') {
        if (this.mutable_content.number_of_shares && this.mutable_content.number_of_times_saved) this.invalid_internal = false
        else this.invalid_internal = true
      } else if (this.mutable_content.network === 'blog') {
        if (this.mutable_content.number_of_comments && this.mutable_content.number_of_shares) this.invalid_internal = false
        else this.invalid_internal = true
      } else if (this.mutable_content.content_type === 'streaming' || this.mutable_content.content_type === 'clip' || this.mutable_content.content_type === 'story') {
        if (this.mutable_content.number_of_impressions !== null && this.mutable_content.number_of_likes !== null && this.mutable_content.number_of_comments !== null && this.mutable_content.number_of_shares !== null) this.invalid_internal = false
        else this.invalid_internal = true
      } else if (this.mutable_content.number_of_impressions !== null && this.mutable_content.number_of_likes !== null && this.mutable_content.number_of_comments !== null && this.mutable_content.number_of_shares !== null && this.mutable_content.number_of_times_saved !== null) this.invalid_internal = false
      else this.invalid_internal = true
    },
    changeValue(new_value, key) {
      this.content[key] = new_value
      const total = this.mutable_content.number_of_likes + this.mutable_content.number_of_comments + this.mutable_content.number_of_shares + this.mutable_content.number_of_times_saved
      this.checkFormInternalExternal()
      this.engagements = utils.separatebycomma(total)
    },
    onSubmit() {
      if (this.invalid_internal) return

      const new_data = {
        impressions: this.mutable_content.number_of_impressions,
        likes: this.mutable_content.number_of_likes,
        comments: this.mutable_content.number_of_comments,
        shares: this.mutable_content.number_of_shares,
        saved: this.mutable_content.number_of_times_saved
      }

      this.$emit('onSubmit', new_data)
    },
  },
};
</script>